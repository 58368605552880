<template>
  <div class="row">
    <div class="col-12">
      Codebox Code Is Auto Generated
    </div>
    <div class="col-12">
      <ElFormItem
        prop="access_instructions"
        :rules="accessInstructionsRules.access_instructions"
      >
        <ElTooltip
          :visible-arrow="false"
          :disabled="$viewport.lt.md"
          popper-class="popover-panel"
          placement="right-start"
        >
          <template slot="content">
            <i class="sdicon-info-circle" />
            <p>
              Here you can add any helpful information agents should know.
              <br>
              <br>
              For example - where is the lockbox? Are there any general building access instructions?
            </p>
          </template>
          <SdFloatLabel>
            <ElInput
              v-model="accessPayloadRef.access_instructions"
              type="textarea"
              :rows="4"
              placeholder="Notes to agent"
              @input="emitStateChanged"
            />
          </SdFloatLabel>
        </ElTooltip>
      </ElFormItem>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';

export default {
  name: 'ShowingProfileAccessInstructionWithoutCodebox',
  components: {},
  props: {
    unit: {
      type: Object,
      default: null,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const accessPayloadRef = ref(props.payload);
    const activeShowingProfileRef = ref(props.unit.active_showing_profile);
    const isSelfShowing = activeShowingProfileRef.value?.is_self_showing;

    return {
      accessPayloadRef,
      accessInstructionsRules,
      emitStateChanged,
      isSelfShowing,
    };

    function emitStateChanged() {
      context.emit('update-payload', {
        access_instructions: accessPayloadRef.value.access_instructions,
      });
    }
  },
};

const defaultPayload = {
  access_instructions: '',
};

export const accessInstructionsRules = {
  access_instructions: [
    {
      required: 'true',
      message: 'Notes to agent are required',
    },
    {
      type: 'string',
      message: 'Notes to agent must be a string',
    },
  ],
};
</script>

<style lang="scss">
.loader-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>
